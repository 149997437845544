import React from "react";
import { ITemplate } from "@Templates/ITemplate";
import TopFeatureCard from "./TopFeatureCard";
import styles from "./TopFeaturesSection.module.scss";

const TopFeaturesSection = ({
    hideComponent,
    data,
    title,
}: ITemplate.ITopFeaturesSectionProps) => {
    if (hideComponent) return null;
    return (
        <div className={styles.section1Root}>
            <div className={styles.section1Box}>
                <div className={styles.section1Title}>
                    <div className={styles.title}>{title}</div>
                </div>
                <div className={styles.contentBox}>
                    {data.map((stat, index) => (
                        <TopFeatureCard
                            key={stat.id}
                            data={stat}
                            index={index + 1}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TopFeaturesSection;
