import React from "react";
import { CustomCodeSection as CustomCodeSectionBase } from "@Modules/customCode/CustomCodeSection";
import { classNames } from "repoV2/utils/common/render/classNames";
import styles from "../../root/growth.module.scss";

export const CustomCodeSection = ({ position }: { position: number }) => (
    <CustomCodeSectionBase
        position={position}
        Title={({ children }) => (
            <div
                className={classNames(
                    styles.sectionTitle,
                    "pb-3 pt-5 mt-2 text-center"
                )}
            >
                {children}
            </div>
        )}
        Section={({ children }) => (
            <section className="px-3 pb-5">{children}</section>
        )}
    />
);
