import { ITemplate } from "@Templates/ITemplate";
import React, { useState } from "react";
import { TEMPLATE_KEYS } from "@Utils";
import { classNames } from "repoV2/utils/common/render/classNames";
import { TeamMemberSocialIcons } from "@Modules/hostPage";
import styles from "./teamMemberCard.module.scss";
import commonStyles from "../../../../yoga/root/yoga.module.scss";

const TeamMemberCard = ({
    data,
    cardClick,
}: ITemplate.ITeamMemberCardProps) => {
    const readMore = useState(false);
    return (
        <div className={styles.root} onClick={() => cardClick(data)}>
            <div
                className={classNames(
                    styles.container,
                    commonStyles.borderRadius
                )}
            >
                <div
                    className={classNames(
                        styles.image,
                        commonStyles.borderRadius
                    )}
                    style={{ backgroundImage: `url(${data?.displayImage})` }}
                >
                    <TeamMemberSocialIcons
                        template={TEMPLATE_KEYS.YOGA}
                        socialInfo={data.socialInfo}
                    />
                </div>
                <div className={styles.detailsContainer}>
                    <div className={styles.name}>
                        {data.memberName.slice(0, 21)}
                    </div>
                    <div className={styles.heading}>
                        {data.staffHeading.slice(0, 60)}
                    </div>
                    <hr className={styles.horizontalBar} />
                    <p className={styles.staffDescription}>
                        {readMore
                            ? data.staffDescription
                            : `${data.staffDescription.substring(0, 30)}`}

                        <br />
                    </p>
                </div>
                <div className={styles.readMoreButton}>Show more...</div>
            </div>
        </div>
    );
};
export default TeamMemberCard;
