import { IHost } from "@Interfaces";
import { trimText } from "@Utils";
import { getFixedLengthNumString } from "repoV2/utils/common/dataTypes/string";
import React from "react";
import styles from "./TopFeatureCard.module.scss";

const TopFeatureCard = ({
    data,
    index,
}: {
    data: IHost.ICreatorTopFeature;
    index: number;
}) => (
    <div className={styles.cardContainer}>
        <div className={styles.num}>{getFixedLengthNumString(index)}</div>
        <div className={styles.section2box}>
            <div className={styles.item1}>{trimText(data.title, 30)}</div>
            <div className={styles.item2}>
                {trimText(data.description, 120)}
            </div>
        </div>
    </div>
);

export default TopFeatureCard;
