/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { ITemplate } from "@Templates/ITemplate";
import { DotMatrixIconSvg, SECTION_IDS, CurvedArrowSvg, MEDIA } from "@Utils";
import { classNames } from "repoV2/utils/common/render/classNames";
import { ReadMoreWrapper } from "@Modules/common/ReadMoreWrapper";
import { ButtonV2 } from "@Modules/common/ButtonV2";
import { FollowerCountIcons } from "@Modules/hostPage/FollowerCountIcons";
import { DEFAULT_PROFILE_PIC_SRC } from "repoV2/utils/common/assets/assets";
import {
    TEMPLATE_CONFIG,
    TEMPLATE_KEYS,
} from "repoV2/features/CreatorPages/CreatorPages.constants";
import { getOptimisedImageUrl } from "repoV2/features/Common/modules/ExlyImage/utils/getOptimisedImageUrl";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import styles from "./aboutUs.module.scss";
import commonStyles from "../../../yoga/root/yoga.module.scss";

const AboutUs = ({
    coverImg,
    name,
    title,
    shortDescription,
    getInTouchCtaText,
    onGetInTouchClick,
    profileImg,
    description,
    hideSocialIcons,
    socialLinks,
    expandHostDesc,
    hideGetInTouch,
}: ITemplate.IAboutSectionProps): JSX.Element => {
    const optimisedImageUrl = getOptimisedImageUrl({
        imageUrl: coverImg || MEDIA.DEFAULT.BACKGROUND.EDUVERSE,
        fetchWidth: TEMPLATE_CONFIG[TEMPLATE_KEYS.ELEMENTARY].coverImage.width,
    });
    return (
        <section>
            <a id={SECTION_IDS.about} className={commonStyles.sectionAnchor} />
            <div
                className={styles.heroSection}
                style={{
                    backgroundImage: `url(${optimisedImageUrl}),
                    linear-gradient(rgba(31, 31, 31, 0.85), rgba(31, 31, 31, 0.65))`,
                }}
            >
                <div className={styles.heroContent}>
                    {title ? <h1 className={styles.title}>{title}</h1> : null}
                    {shortDescription ? (
                        <p
                            className={classNames(
                                styles.shortDescription,
                                styles.desktopOnly
                            )}
                        >
                            {shortDescription}
                        </p>
                    ) : null}
                    {hideGetInTouch ? null : (
                        <div className={styles.bannerCtaContainer}>
                            <ButtonV2
                                applyTheme
                                onClick={() => {
                                    onGetInTouchClick();
                                }}
                                className={styles.bannerCta}
                            >
                                {getInTouchCtaText}
                            </ButtonV2>
                            <div className={styles.heroCtaArrow}>
                                <CurvedArrowSvg
                                    className={styles.arrowIcon}
                                    stroke="rgb(var(--rgbColor2-schema1-host-theme, 255, 255, 255))"
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className={styles.creatorIntro}>
                <DotMatrixIconSvg className={styles.dotMatrix} />

                <div className={styles.introContent}>
                    {name ? (
                        <div className={styles.name}>About {name}</div>
                    ) : null}
                    {description ? (
                        <>
                            {expandHostDesc ? (
                                <div
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                        __html: description,
                                    }}
                                />
                            ) : (
                                <ReadMoreWrapper
                                    id="eduverse_aboutSection_description"
                                    collapsedHeight={200}
                                    buttonClassName={styles.readMoreButton}
                                >
                                    <div
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={{
                                            __html: description,
                                        }}
                                    />
                                </ReadMoreWrapper>
                            )}
                        </>
                    ) : null}
                    <div className={`d-flex justify-content-center `}>
                        <FollowerCountIcons
                            socialLinks={socialLinks}
                            hideSocialIcons={hideSocialIcons}
                        />
                    </div>
                </div>
                <ExlyImage
                    fetchWidth={550}
                    className={styles.profileImg}
                    src={profileImg || DEFAULT_PROFILE_PIC_SRC}
                    alt={name || ""}
                />
                {shortDescription ? (
                    <p
                        className={classNames(
                            styles.shortDescription,
                            styles.mobileOnly
                        )}
                    >
                        {shortDescription}
                    </p>
                ) : null}
            </div>
        </section>
    );
};

export default AboutUs;
