import { ITemplate } from "@Templates/ITemplate";
import { DotMatrixIconSvg, trimText } from "@Utils";
import React from "react";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import styles from "./StatsSection.module.scss";

const StatsSection = ({
    hideComponent,
    title,
    data,
}: ITemplate.IStatsSectionProps) => {
    if (hideComponent) return null;
    return (
        <div className={`pt-4 ${styles.section1Root}`}>
            <div className={styles.section1Box}>
                <div className={styles.section1Title}>
                    <p>{title}</p>
                </div>
                <DotMatrixIconSvg className={styles.dotMatrix} />
                <div className={styles.contentBox}>
                    {data.map(item => (
                        <div className={styles.content} key={item.id}>
                            {item.media ? (
                                <ExlyImage
                                    fetchWidth={200}
                                    className={styles.leftItem}
                                    src={item.media}
                                    alt=""
                                />
                            ) : (
                                <div className={styles.leftItem} />
                            )}
                            <div className={styles.rightItem}>
                                <div className={styles.count}>
                                    {trimText(item.value, 7)}
                                </div>
                                <div className={styles.program}>
                                    {trimText(item.description, 50)}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default StatsSection;
